import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
  },
  reducers: {
    user_loaded: (state, action) => {
      console.log(state, action);
      return {
        ...state,
        user: action.payload,
      };
    },
  },
});
export const { user_loaded } = authSlice.actions;

export default authSlice.reducer;
