import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { filterToCondition } from "../../../utils/filterToCondition.js";

const useFiltersData = (filters, setConditions, value) => {
  const [industries, setIndustries] = useState();
  const [sectors, setSectors] = useState();
  const [exchange, setExchange] = useState();
  const formatIndustry = useCallback((array) => {
    if (!array) return [];
    let visit = new Set(array);
    const filterIndustry = [{ name: "Industries", value: "" }];
    const industryObj = {
      label: "Industry",
      key: "industry",
      type: "dropdown",
      options: [],
    };

    visit.forEach((i) => {
      if (i === "") return;
      industryObj.options.push({
        name: `${i}`,
        query: {
          operator: "eq",
          operands: ["sector", `${i}`],
        },
      });
      filterIndustry.push({
        name: `${i}`,
        value: `${i}`,
      });
    });

    return { industryObj, filterIndustry };
  }, []);
  const formatSector = useCallback((array) => {
    if (!array) return [];
    let visit = new Set(array);

    const filterSector = [{ name: "Sector", value: "" }];
    const sectorObj = {
      label: "Sector",
      key: "sector",
      type: "dropdown",
      options: [],
    };

    //Format, remove duplicates and remove empty values
    // visit.delete("");

    visit.forEach((i) => {
      if (i === "") return;
      sectorObj.options.push({
        name: `${i}`,
        query: {
          operator: "eq",
          operands: ["sector", `${i}`],
        },
      });
      filterSector.push({
        name: `${i}`,
        value: `${i}`,
      });
    });

    return { sectorObj, filterSector };
  }, []);
  const formatExchange = useCallback((array) => {
    if (!array) return [];
    let visit = new Set(array);

    const filterExchange = [{ name: "Exchange", value: "" }];
    const exchangeObj = {
      label: "Exchange",
      key: "exchange_short_name",
      type: "dropdown",
      options: [],
    };

    //Format, remove duplicates and remove empty values
    // visit.delete("");

    visit.forEach((i) => {
      if (i === "") return;
      exchangeObj.options.push({
        name: `${i}`,
        query: {
          operator: "eq",
          operands: ["sector", `${i}`],
        },
      });
      filterExchange.push({
        name: `${i}`,
        value: `${i}`,
      });
    });

    return { exchangeObj, filterExchange };
  }, []);

  useEffect(() => {
    const getIndustries = async () => {
      const res = await axios.get("/api/industry");
      setIndustries(res.data.industries);
      setSectors(res.data.sectors);
      setExchange(res.data.exchange);
    };
    getIndustries();
  }, []);
  const { industryObj, filterIndustry } = useMemo(
    () => formatIndustry(industries),
    [industries, formatIndustry]
  );
  const { sectorObj, filterSector } = useMemo(
    () => formatSector(sectors),
    [sectors, formatSector]
  );
  const { exchangeObj, filterExchange } = useMemo(
    () => formatExchange(exchange),
    [exchange, formatExchange]
  );

  const prevValueRef = useRef(value);
  useEffect(() => {
    prevValueRef.current = value;
  }, [value]);
  useEffect(() => {
    const newConditions = filterToCondition(
      filters,
      industryObj,
      sectorObj,
      exchangeObj,
      prevValueRef.current
    );
    setConditions((prevConditions) => {
      if (JSON.stringify(prevConditions) !== JSON.stringify(newConditions)) {
        return newConditions;
      }
      return prevConditions;
    });
  }, [filters, industryObj, sectorObj, exchangeObj, setConditions]);
  return {
    industries,
    sectors,
    exchange,
    filterIndustry,
    filterSector,
    filterExchange,
  };
};

export default useFiltersData;
