import axios from "axios";
import { useEffect, useState } from "react";

const useChartData = (conditions, stockSymbol) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // const createConditionsChart = () => {
    //   if (!conditions || !conditions.operands) {
    //     return { operator: "and", operands: [] };
    //   }

    //   const validOperands = conditions.operands.filter(
    //     (op) => op.operands && op.operands[0] !== "rule_of_forty"
    //   );

    //   return {
    //     operator: "and",
    //     operands: validOperands,
    //   };
    // };
    // const conditionsChart = createConditionsChart();

    const chartData = async () => {
      const chartBody = {
        query: conditions,
        // conditionsChart.operands.length > 0 ? conditionsChart : null,
        symbol: stockSymbol,
      };
      const chartRes = await axios.post("/api/company/chartData", chartBody);
      setChartData(chartRes.data);
    };
    chartData();
  }, [conditions, stockSymbol]);
  return chartData;
};

export default useChartData;
