import CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';

export const encrypt = (text) => {
  const iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
  const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse("eef1bf89ac86b963bb501179dd00c4ffa8b630f1"), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return Base64.encodeURL(encrypted.toString()) 
}