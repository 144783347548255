import React from "react";
import Styles from "./Modal.module.css";
import closeSvg from "../../../images/close.svg";
import Button from "../Button/Button.js";

const Modal = ({ open, setOpen, children, title }) => {
  return open ? (
    <div className={Styles.Backdrop}>
      <div className={Styles.ModalWrapper}>
        <div className={Styles.ModalHeader}>
          <h5 className={Styles.Title}>{title}</h5>
          <Button
            style={{
              background: "none",
            }}
            onClick={() => setOpen(false)}
          >
            <img src={closeSvg} alt="close" width={20} height={20} />
          </Button>
        </div>
        <div className={Styles.Body}>{children}</div>
      </div>
    </div>
  ) : null;
};

export default Modal;
