const useChartLogic = ({ chartData, data }) => {
  const total =
    +chartData.less_than_zero +
    +chartData.between_zero_and_forty +
    +chartData.greater_than_forty;

  const lessThanZero = {
    variant: "danger",
    title: "Unhealthy",
    value: chartData
      ? ((chartData.less_than_zero / total) * 100).toFixed(0)
      : 0,
  };
  const betweenZeroAndForty = {
    variant: "primary",
    title: "Medium",
    value: chartData
      ? ((chartData.between_zero_and_forty / total) * 100).toFixed(0)
      : 0,
  };
  const greaterThanForty = {
    variant: "success",
    title: "Healthy",
    value: chartData
      ? ((chartData.greater_than_forty / total) * 100).toFixed(0)
      : 0,
  };
  return {
    lessThanZero,
    betweenZeroAndForty,
    greaterThanForty,
    total,
  };
};

export default useChartLogic;
