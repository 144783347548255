import React from "react";
import Navigation from "../../../components/Layout/Navigation/Navigation.js";
import DropDownList from "../../../components/Layout/DropdownList/DropDownList.js";
import Styles from "./Faq.module.css";
import Seo from "../../../utils/SeoHelmet/Seo.js";
const FaqPage = () => {
  const optionsList = [
    {
      title: "What is Rule of 40?",
      text: "The Rule of 40 is a principle that states a software company's combined revenue growth rate and profit margin should equal or exceed 40%. ",
    },
    {
      title: "How Can I request more data?",
      text: "We provide data for thousands companies but, if you need more, feel free to request with the request button.",
    },
  ];
  return (
    <div className={Styles.Page}>
      <Seo description={"Rule of 40 F.A.Q."} />
      <Navigation />
      <div className={Styles.PageContent}>
        <div className={Styles.PageTitle}>
          <h3>F.A.Q.</h3>
          <p>
            Our Rule of 40 tool is designed to identify companies that meet this
            criteria.
          </p>
        </div>
        <DropDownList options={optionsList} />
      </div>
    </div>
  );
};

export default FaqPage;
