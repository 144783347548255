export const onClick = (filterName, optionName, setFilters) => {
  setFilters((prev) => {
    const currentFilter = prev[filterName] || [];
    if (currentFilter.includes(optionName)) {
      return {
        ...prev,
        [filterName]: currentFilter.filter((item) => item !== optionName),
      };
    } else {
      return {
        ...prev,
        [filterName]: [optionName],
      };
    }
  });
};
