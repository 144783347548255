import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./redux/reducers/auth.js";

// const initialState = {}
// const middleware = [thunk]
const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
  },
});

export default store;
