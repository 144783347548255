import React from "react";
import Styles from "./DropDownList.module.css";

const DropDownList = ({ options }) => {
  return (
    <div className={Styles.DropDownList}>
      {options.map((option, index) => {
        return (
          <div key={index} className={Styles.DropDownContent}>
            <h5>{option.title}</h5>
            <p>{option.text}</p>
          </div>
        );
      })}
    </div>
  );
};

export default DropDownList;
