import React from "react";
import Styles from "./Input.module.css";

const Input = ({
  id,
  type,
  placeholder,
  htmlFor,
  label,
  textLabel,
  required,
  value,
  onChange,
  name,
  error,
  pattern,
  checked,
  index,
}) => {
  return type === "textarea" ? (
    <div className={Styles.InputWrapper}>
      {label ? (
        <label htmlFor={htmlFor} className={Styles.Label}>
          {textLabel}
          {required ? <span className={Styles.Required}>*</span> : null}
        </label>
      ) : null}
      <textarea
        id={id}
        name={name}
        type={type}
        rows={8}
        cols={1}
        style={{ height: "auto" }}
        className={error ? `${Styles.Input} ${Styles.Error}` : Styles.Input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        pattern={pattern}
      />
    </div>
  ) : type === "checkbox" ? (
    <div className={Styles.CheckboxWrapper} key={index}>
      <input
        id={`modal-checkbox-${value}`}
        name={name}
        value={value}
        type={type}
        className={Styles.Checkbox}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={`modal-checkbox-${value}`}>{textLabel}</label>
    </div>
  ) : (
    <div className={Styles.InputWrapper}>
      {label ? (
        <label htmlFor={htmlFor} className={Styles.Label}>
          {textLabel}
          {required ? <span className={Styles.Required}>*</span> : null}
        </label>
      ) : null}
      <input
        id={id}
        name={name}
        type={type}
        className={error ? `${Styles.Input} ${Styles.Error}` : Styles.Input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        pattern={pattern}
      />
    </div>
  );
};

export default Input;
