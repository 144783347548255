import AppRoutes from "./Routes.js";
import store from "./store.js";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </HelmetProvider>
  );
}

export default App;
