import React from "react";
import { ProgressCircularBar } from "../../../Common/index.js";
import Styles from "./ChartArc.module.css";
import useChartLogic from "../Logic/useChartLogic.js";

const ChartArc = ({ chartData, data }) => {
  const { lessThanZero, betweenZeroAndForty, greaterThanForty, total } =
    useChartLogic({
      chartData,
      data,
    });

  return (
    <div className={Styles.ChartWrapperContent}>
      <div className={Styles.ChartArcWrapper}>
        {[lessThanZero, betweenZeroAndForty, greaterThanForty].map(
          (item, index) => {
            return (
              <div className={Styles.ChartArcRelativeWrapper}>
                <ProgressCircularBar
                  value={isNaN(item.value) ? 0 : item.value}
                  variant={item.variant}
                  title={item.title}
                />

                <p className={Styles.ChartArcLegend}>
                  {index === 0
                    ? chartData.less_than_zero
                    : index === 1
                    ? chartData.between_zero_and_forty
                    : index === 2
                    ? chartData.greater_than_forty
                    : null}{" "}
                  companies
                </p>
              </div>
            );
          }
        )}
      </div>
      <h3 className={Styles.DescriptionArc}>Total: {total}</h3>
      {/* <p className={Styles.DescriptionArc}>Total number of companies.</p> */}
    </div>
  );
};

export default ChartArc;
