import axios from "axios";
import { useEffect, useState } from "react";
import { encrypt } from "../../../utils/encrypt.js";

const useDashboardLogic = (
  conditions,
  page,
  rowsPerPage,
  stockSymbol,
  sortField,
  sortType,
  displayColumns,
  setFilters,
  handleResetColumn
) => {
  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvDataFiltered, setCsvDataFiltered] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const body = {
        offset: page * rowsPerPage,
        size: rowsPerPage,
        sortField: sortField,
        sortType: sortType,
        query: conditions,
        symbol: stockSymbol,
      };
      let ecryptedText = encodeURIComponent(encrypt(JSON.stringify(body)));
      // console.log(ecryptedText)
      const res = await axios.get(`/api/company/${ecryptedText}`);
      setData(res.data);

      const cvsBody = {
        sortField: sortField,
        sortType: sortType,
        query: conditions,
        symbol: stockSymbol,
      };
      ecryptedText = encodeURIComponent(encrypt(JSON.stringify(cvsBody)));
      const resCsv = await axios.get(`/api/company/${ecryptedText}`);
      setCsvData(resCsv.data.result);
    };

    getData();
  }, [conditions, page, rowsPerPage, stockSymbol, sortField, sortType]);

  useEffect(() => {
    const filterCsvData = (data, columns) => {
      const columnsKeys = Object.keys(columns).filter((key) => columns[key]);
      const filteredData = data.map((item) => {
        const filteredItem = Object.keys(item)
          .filter((key) => columnsKeys.includes(key))
          .reduce((obj, key) => {
            obj[key] = item[key];
            return obj;
          }, {});
        return filteredItem;
      });
      setCsvDataFiltered(filteredData);
    };

    filterCsvData(csvData, displayColumns);
  }, [csvData, displayColumns]);

  const clearFilters = () => {
    setFilters({
      exchange_short_name: null,
      industry: null,
      rule_of_forty: null,
      sector: null,
    });
    handleResetColumn();
  };

  return {
    data,
    csvDataFiltered,
    clearFilters,
  };
};

export default useDashboardLogic;
