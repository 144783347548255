import { createColumnHelper } from "@tanstack/react-table";
import React, { useState } from "react";
import Styles from "../ui/Dashboard.module.css";
const columnHelper = createColumnHelper();
const useDashboardColumns = () => {
  const [sortField, setSortField] = useState("stock_symbol");
  const [sortType, setSortType] = useState("");
  const [displayColumns, setDisplayColumns] = useState({
    stock_symbol: true,
    rule_of_forty: true,
    price_change_percentage: false,
    sector: false,
    exchange_short_name: false,
    industry: false,
    open_price: false,
    beta: false,
    is_etf: false,
    theme: false,
    subindustry: false,
    revenue_growth: false,
    optionable: false,
    net_profit_marging: false,
    eps: false,
    earning_announcement: false,
    shares_outstanding: false,
  });
  const handleCheckboxClick = (item) => {
    setDisplayColumns((prev) => ({
      ...prev,
      [item]: !prev[item],
    }));
  };

  const handleResetColumn = () => {
    setDisplayColumns((prevState) =>
      Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {})
    );
  };

  const columns = [
    columnHelper.accessor("stock_symbol", {
      header: () => (
        <p
          className={Styles.TableHeaders}
          onClick={() => {
            setSortField("stock_symbol");
            setSortType((prevState) => (prevState === "ASC" ? "DESC" : "ASC"));
          }}
        >
          Symbol{" "}
          {sortType === "DESC" && sortField === "stock_symbol" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="size-4"
              width={"20px"}
              height={"20px"}
            >
              <path
                fillRule="evenodd"
                d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                clipRule="evenodd"
              />
            </svg>
          ) : sortType === "ASC" && sortField === "stock_symbol" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="size-4"
              width={"20px"}
              height={"20px"}
            >
              <path
                fillRule="evenodd"
                d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                clipRule="evenodd"
              />
            </svg>
          ) : null}
        </p>
      ),
      cell: ({ row }) => {
        return (
          <div className={Styles.SymbolWrapper}>
            <p>
              {row.original.stock_symbol}
              <span>{row.original.company_name}</span>
            </p>
          </div>
        );
      },
    }),
    columnHelper.accessor("industry", {
      header: () =>
        displayColumns.industry ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("industry");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Industry{" "}
            {sortType === "DESC" && sortField === "industry" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "industry" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) => {
        return displayColumns.industry ? (
          <p className={Styles.Industry}>{row.original.industry}</p>
        ) : null;
      },
    }),
    columnHelper.accessor("sector", {
      header: () =>
        displayColumns.sector ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("sector");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Sector{" "}
            {sortType === "DESC" && sortField === "sector" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "sector" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) => {
        return displayColumns.sector ? (
          <p className={Styles.Industry}>{row.original.sector}</p>
        ) : null;
      },
    }),
    columnHelper.accessor("price_change_percentage", {
      header: () =>
        displayColumns.price_change_percentage ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("price_change_percentage");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Price Change{" "}
            {sortType === "DESC" && sortField === "price_change_percentage" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" &&
              sortField === "price_change_percentage" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) => {
        return displayColumns.price_change_percentage ? (
          <p
            className={Styles.Industry}
            style={
              +row.original.price_change_percentage < 0
                ? { color: "rgb(251, 72, 72)" }
                : { color: "rgb(50, 198, 84)" }
            }
          >
            {row.original.price_change_percentage} %
          </p>
        ) : null;
      },
    }),
    columnHelper.accessor("open_price", {
      header: () =>
        displayColumns.open_price ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("open_price");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Stock Price{" "}
            {sortType === "DESC" && sortField === "open_price" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "open_price" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) => {
        return displayColumns.open_price ? (
          <p
            className={Styles.Industry}
            style={{ color: "var(--color-gray-95)" }}
          >
            ${row.original.open_price}
          </p>
        ) : null;
      },
    }),

    columnHelper.accessor("rule_of_forty", {
      header: () => (
        <p
          className={Styles.TableHeaders}
          onClick={() => {
            setSortField("rule_of_forty");
            setSortType((prevState) => (prevState === "ASC" ? "DESC" : "ASC"));
          }}
        >
          Scale{" "}
          {sortType === "DESC" && sortField === "rule_of_forty" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="size-4"
              width={"20px"}
              height={"20px"}
            >
              <path
                fillRule="evenodd"
                d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                clipRule="evenodd"
              />
            </svg>
          ) : sortType === "ASC" && sortField === "rule_of_forty" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="size-4"
              width={"20px"}
              height={"20px"}
            >
              <path
                fillRule="evenodd"
                d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                clipRule="evenodd"
              />
            </svg>
          ) : null}
        </p>
      ),
      cell: ({ row }) => {
        return (
          <div>
            {
              <p
                className={
                  Math.floor(row.original.rule_of_forty * 100) / 100 < 0
                    ? Styles.Red
                    : Math.floor(row.original.rule_of_forty * 100) / 100 > 0 &&
                      Math.floor(row.original.rule_of_forty * 100) / 100 < 40
                    ? Styles.Blue
                    : Styles.Green
                }
              >
                {Math.floor(row.original.rule_of_forty * 100) / 100} %
              </p>
            }
          </div>
        );
      },
    }),

    columnHelper.accessor("exchange_short_name", {
      header: () =>
        displayColumns.exchange_short_name ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("exchange_short_name");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Exchange{" "}
            {sortType === "DESC" && sortField === "exchange_short_name" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "exchange_short_name" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) =>
        displayColumns.exchange_short_name ? (
          <p className={Styles.Industry}>{row.original.exchange_short_name}</p>
        ) : null,
    }),
    columnHelper.accessor("beta", {
      header: () =>
        displayColumns.beta ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("beta");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Beta{" "}
            {sortType === "DESC" && sortField === "beta" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "beta" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) =>
        displayColumns.beta ? (
          <p className={Styles.Industry}>{row.original.beta}</p>
        ) : null,
    }),
    columnHelper.accessor("earning_announcement", {
      header: () =>
        displayColumns.earning_announcement ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("earning_announcement");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Earning Announcement{" "}
            {sortType === "DESC" && sortField === "earning_announcement" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "earning_announcement" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) =>
        displayColumns.earning_announcement ? (
          <p className={Styles.Industry}>
            {new Date(row.original.earning_announcement).toLocaleDateString()}
          </p>
        ) : null,
    }),
    columnHelper.accessor("eps", {
      header: () =>
        displayColumns.eps ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("eps");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Eps{" "}
            {sortType === "DESC" && sortField === "eps" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "eps" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) =>
        displayColumns.eps ? (
          <p className={Styles.Industry}>{row.original.eps}</p>
        ) : null,
    }),
    columnHelper.accessor("is_etf", {
      header: () =>
        displayColumns.is_etf ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("is_etf");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Etf{" "}
            {sortType === "DESC" && sortField === "isEtf" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "isEtf" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) =>
        displayColumns.is_etf ? (
          <p className={Styles.Industry}>
            {row.original.is_etf === false ? "False" : "True"}
          </p>
        ) : null,
    }),
    columnHelper.accessor("net_profit_marging", {
      header: () =>
        displayColumns.net_profit_marging ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("net_profit_marging");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Net Profit{" "}
            {sortType === "DESC" && sortField === "net_profit_marging" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "net_profit_marging" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) =>
        displayColumns.net_profit_marging ? (
          <p className={Styles.Industry}>
            {Number(row.original.net_profit_marging).toFixed(2)} %
          </p>
        ) : null,
    }),
    columnHelper.accessor("optionable", {
      header: () =>
        displayColumns.optionable ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("optionable");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Optionable{" "}
            {sortType === "DESC" && sortField === "optionable" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "optionable" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) =>
        displayColumns.optionable ? (
          <p className={Styles.Industry}>
            {row.original.optionable === false ? "False" : "True"}
          </p>
        ) : null,
    }),
    columnHelper.accessor("revenue_growth", {
      header: () =>
        displayColumns.revenue_growth ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("revenue_growth");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Revenue Growth{" "}
            {sortType === "DESC" && sortField === "revenue_growth" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "revenue_growth" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) =>
        displayColumns.revenue_growth ? (
          <p className={Styles.Industry}>
            {Number(row.original.revenue_growth).toFixed(2)} %
          </p>
        ) : null,
    }),
    columnHelper.accessor("subindustry", {
      header: () =>
        displayColumns.subindustry ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("subindustry");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Subindustry{" "}
            {sortType === "DESC" && sortField === "subindustry" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "subindustry" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) =>
        displayColumns.subindustry ? (
          <p className={Styles.Industry}>{row.original.subindustry}</p>
        ) : null,
    }),
    columnHelper.accessor("theme", {
      header: () =>
        displayColumns.theme ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("theme");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Theme{" "}
            {sortType === "DESC" && sortField === "theme" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "theme" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) =>
        displayColumns.theme ? (
          <p className={Styles.Industry}>{row.original.theme}</p>
        ) : null,
    }),
    columnHelper.accessor("shares_outstanding", {
      header: () =>
        displayColumns.shares_outstanding ? (
          <p
            className={Styles.TableHeaders}
            onClick={() => {
              setSortField("shares_outstanding");
              setSortType((prevState) =>
                prevState === "ASC" ? "DESC" : "ASC"
              );
            }}
          >
            Shares Outstanding{" "}
            {sortType === "DESC" && sortField === "shares_outstanding" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : sortType === "ASC" && sortField === "shares_outstanding" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
                width={"20px"}
                height={"20px"}
              >
                <path
                  fillRule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          </p>
        ) : null,
      cell: ({ row }) =>
        displayColumns.shares_outstanding ? (
          <p className={Styles.Industry}>{row.original.shares_outstanding}</p>
        ) : null,
    }),
  ];

  return {
    columns,
    displayColumns,
    setDisplayColumns,
    sortField,
    sortType,
    handleCheckboxClick,
    handleResetColumn,
  };
};

export default useDashboardColumns;
