import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Login from "./Pages/Login/ui/Login.js";
import Dashboard from "./Pages/Dashboard/ui/Dashboard.js";
import FaqPage from "./Pages/FAQ/ui/Faq.js";
import ContactUs from "./Pages/ContactUs/ui/ContacUs.js";

// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import moment from "moment";
const AppRoutes = () => {
  // const [isAuthenticated, setIsAuthenticated] = useState(true);
  // const [loading, setIsLoading] = useState(true);

  // const RequireAuth = ({ children, redirectTo }) => {
  //   if (loading) return <div></div>;
  //   return isAuthenticated ? children : <Navigate to={redirectTo} />;
  // };
  // const NotRequireAuth = ({ children, redirectTo }) => {
  //   if (loading) return <div></div>;
  //   return !isAuthenticated ? children : <Navigate to={redirectTo} />;
  // };

  // useEffect(() => {
  //   const autoLogout = () => {
  //     const token = Cookies.get("token");
  //     if (token) {
  //       const decodededToken = jwtDecode(token);
  //       if (moment.unix(decodededToken.exp).isBefore(moment())) {
  //         setIsAuthenticated(false);
  //         Cookies.remove("token");
  //       } else {
  //         setIsAuthenticated(true);
  //       }
  //     } else {
  //       setIsAuthenticated(false);
  //     }
  //     setIsLoading(false);
  //   };
  //   autoLogout();
  //   const intervalId = setInterval(autoLogout, 10000);

  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route
          path="/login"
          element={
            <NotRequireAuth redirectTo={"/"}>
              <Login setIsAuthenticated={setIsAuthenticated} />
            </NotRequireAuth>
          }
        /> */}

        <Route index element={<Dashboard />} />
        <Route path="faq" element={<FaqPage />} />
        <Route path="contact-us" element={<ContactUs />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
