import Styles from "../ui/Pagination.module.css";

export default function usePaginationLogic({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) {
  const totalPages = Math.ceil(count / rowsPerPage);
  if (page >= totalPages) {
    onPageChange(0);
  }

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      onPageChange(newPage);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    if (totalPages <= 6) {
      for (let i = 0; i < totalPages; i++) {
        pages.push(
          <button
            key={i}
            className={i === page ? Styles.active : ""}
            onClick={() => handlePageChange(i)}
          >
            {i + 1}
          </button>
        );
      }
    } else {
      let startPage = Math.max(page - 2, 0);
      let endPage = Math.min(startPage + 4, totalPages - 1);

      if (endPage >= totalPages - 1) {
        startPage = totalPages - 5;
      }

      if (startPage > 0) {
        pages.push(
          <button
            key={0}
            className={0 === page ? Styles.active : ""}
            onClick={() => handlePageChange(0)}
          >
            1
          </button>
        );

        if (startPage > 1) {
          pages.push(
            <span className={Styles.Dots} key="startDots">
              ...
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <button
            key={i}
            className={i === page ? Styles.active : ""}
            onClick={() => handlePageChange(i)}
          >
            {i + 1}
          </button>
        );
      }

      if (endPage < totalPages - 2) {
        pages.push(
          <span className={Styles.Dots} key="endDots">
            ...
          </span>
        );
      }

      if (endPage < totalPages - 1) {
        pages.push(
          <button
            key={totalPages - 1}
            className={totalPages - 1 === page ? Styles.active : ""}
            onClick={() => handlePageChange(totalPages - 1)}
          >
            {totalPages}
          </button>
        );
      }
    }
    return pages;
  };

  return {
    renderPageNumbers,
  };
}
