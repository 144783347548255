import React from "react";
import Styles from "./Button.module.css";

const Button = ({ onClick, children, variant, style }) => {
  const dynamicStyle =
    variant === "danger"
      ? { backgroundColor: "var(--color-error)" }
      : variant === "black"
      ? { backgroundColor: "var(--color-black)" }
      : {};
  const combinedStyle = { ...style, ...dynamicStyle };
  return (
    <button style={combinedStyle} onClick={onClick} className={Styles.Button}>
      {children}
    </button>
  );
};

export default Button;
