import axios from "axios";
import { useState } from "react";

const useCredentialLogic = ({ setNameEmail, nameEmail }) => {
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const updateCredentials = (e) => {
    const { name, value, checked } = e.target;
    // console.log(name, value, checked);
    if (name === "columns") {
      if (checked) {
        setNameEmail((prev) => {
          return {
            ...prev,
            [name]: [...prev[name], value],
          };
        });
      } else {
        setNameEmail((prev) => {
          return {
            ...prev,
            [name]: prev[name].filter((i) => i !== value),
          };
        });
      }
    } else {
      setNameEmail((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const onSubmit = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(nameEmail.email)) {
      setError("Email not valid.");
      return;
    }
    setIsLoading(true);

    const delay = new Promise((resolve) => setTimeout(resolve, 3000));

    try {
      const ip = await axios.get("https://api.ipify.org/?format=json");
      const [res] = await Promise.all([
        axios.post("/api/support/email", { ...nameEmail, ...ip.data }),
        delay,
      ]);

      if (res.status === 200) {
        setSuccess(res.data);
        setError();
      }
    } catch (error) {
      setError(error.response?.data?.message);
      setSuccess();
    } finally {
      setIsLoading(false);
    }
  };
  return {
    updateCredentials,
    onSubmit,
    error,
    loading,
    success,
  };
};

export default useCredentialLogic;
